<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'SFTP BNI GFX'" />
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        rounded
        class="ml-4 white--text text-none"
        @click="$router.push('/sftp-upload')"
      >
        Unggah
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="dataWeb"
      :items-per-page="10"
      @click:row="download"
      @mouseleave:row="mouseleave"
      @mouseover:row="mouseOver"
      :loading="loading"
      class="elevation-0"
    >
      <template v-slot:[`header.name`]="{ header }">
        <span style="margin-right: 15px"
          ><v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon></span
        >
        <span>{{ header.text }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          v-if="item.type === 'file'"
          @click.stop="hapus(item)"
          color="red lighten-1"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.size`]="{ item }">
        <p>{{ item.size_string }}</p>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div style="display: flex">
          <div class="right-links-container">
            <ul
              class="right-list-container flexbox"
              style="list-style-type: none; padding-left: 0"
            >
              <li>
                <v-badge
                  :value="item.is_download == false"
                  bordered
                  color="green"
                  overlap
                >
                  <v-avatar size="38">
                    <v-icon class="amber" v-if="item.type == 'file'" dark
                      >mdi-clipboard-text</v-icon
                    >
                    <v-icon v-if="item.type !== 'file'" class="grey" dark
                      >mdi-folder</v-icon
                    >
                  </v-avatar>
                </v-badge>
                <!-- <v-avatar v-bind="attrs" v-on="on" size="38"> -->

                <!-- </v-avatar> -->
              </li>
              <li>
                <a
                  @mouseleave="mouseleave"
                  @mouseover="mouseOver(item)"
                  style="color: black"
                  >{{ item.name }}</a
                >
                <br />
                <a style="color: grey">{{ convertDate(item.modified_at) }}</a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </v-data-table>

    <div v-if="hover" style="position: absolute; left: 50%; top: 50%">
      <v-card :width="widthSize / 2.5">
        <v-card-title class="black--text mt-8 hoverTitle">
          <v-avatar size="56">
            <v-icon class="amber" v-if="this.hoverData.type == 'file'" dark
              >mdi-clipboard-text</v-icon
            >
            <v-icon class="grey" v-if="this.hoverData.type == 'directory'" dark
              >mdi-folder</v-icon
            >
          </v-avatar>
          <v-row
            class="ml-3"
            style="margin-right: -5px"
            justify="space-between"
          >
            <p class="ml-3">Name:</p>
            <p class="ml-5">{{ this.hoverData.name }}</p>
          </v-row>
        </v-card-title>
        <div>
          <v-row class="ml-3 mr-3" justify="space-between">
            <p class="ml-3">Type:</p>
            <p class="ml-5">{{ this.hoverData.type }}</p>
          </v-row>
          <v-row class="ml-3 mr-3" justify="space-between">
            <p class="ml-3">Size:</p>
            <p class="ml-5">{{ this.hoverData.size_string }}</p>
          </v-row>
          <v-row class="ml-3 mr-3" justify="space-between">
            <p class="ml-3">Parent Folder:</p>
            <p class="ml-5">
              /{{ $route.params.id
              }}{{
                this.hoverData.type == "directory"
                  ? "/" + this.hoverData.name
                  : ""
              }}
            </p>
          </v-row>
        </div>
      </v-card>
    </div>
    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import converters from "../../helpers/converters.js";
import store from "../../store/index.js";
import axios from "axios";
import download from "downloadjs";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import PopupError from "../../components/Popup/PopupError.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";

export default {
  components: {
    HeaderContent,
    PopupError,
    PopupSuccess,
  },

  data() {
    return {
      hoverData: {},
      showErrDialog: false,
      showSuccessDialog: false,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Size", value: "size", sortable: true },
        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },

  created() {
    store.commit("bniGfx/clearDataWeb");
    store.commit("bniGfx/setLoading", true);
    store.dispatch("bniGfx/fetchSFTPData", [
      `${this.$route.params.dir}/${this.$route.params.sub}`,
      false,
    ]);
  },

  computed: {
    ...mapState("bniGfx", ["dataWeb", "hover", "loading"]),
    dataWebs() {
      let newWeb = [];
      this.dataWeb.forEach((a) => {
        if (a.type === "folder") {
          newWeb.push(a);
        }
      });
      return newWeb;
    },
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    convertDate(date) {
      return converters.convertLocaleDate(date);
    },
    download(payload) {
      store.commit("bniGfx/setHover", false);
      store.commit("bniGfx/clearDataWeb");
      if (payload.type == "file") {
        payload.route = `${this.$route.params.dir}/${this.$route.params.sub}`;
        store.dispatch("bniGfx/downloadFileSFTP", payload).then((r) => {
          if (r) this.showErrDialog = true;
        });
      }
    },
    hapus(payload) {
      if (payload.type == "directory") {
        payload.route = `${this.$route.params.dir}/${this.$route.params.sub}`;
        store.dispatch("bniGfx/deleteDirectorySFTP", payload).then((r) => {
          if (r === "success") {
            this.showSuccessDialog = true;
          } else {
            this.showErrDialog = true;
          }
        });
      } else {
        payload.route = `${this.$route.params.dir}/${this.$route.params.sub}`;
        store.dispatch("bniGfx/deleteFileSFTP", payload).then((r) => {
          if (r === "success") {
            this.showSuccessDialog = true;
          } else {
            this.showErrDialog = true;
          }
        });
      }
    },
    mouseOver: function(data) {
      this.hoverData = data;
      store.commit("bniGfx/setHover", true);
    },
    mouseleave: function() {
      store.commit("bniGfx/setHover", false);
    },
  },
};
</script>
